import React, { Component } from 'react';
import './Test.css';
import TestApp from './test.js';
import TestStart from './start.js';
import TestEnd from './end.js';

class Test extends Component {

  state = {
    answers:[],
    currentPage: 0,
    name: '',
    rut: '',
    currentQuestionId: 0,
    currentScore: 0
  }

  start = (name,rut) => {
    this.setState({
      currentPage: 1,
      name:name,
      rut:rut,
      currentQuestionId: 0,
      currentScore: 0
    })
  }

  setName = (name) => {
    this.setState({
      name:name
    })
  }

  setRut = (rut) => {
    this.setState({
      rut:rut
    })
  }

  restart = () => {
    this.setState({
      answers: [],
      currentQuestionId: 0,
      currentScore: 0,
      currentPage:1
    });
  }

  sendResults = () => {
    this.props.sendResultsHandler({
      name: this.state.name,
      rut: this.state.rut,
      score: parseInt(this.state.currentScore*100 / this.props.questions.length,10)
    });
  }

  selectAnswerHandler = (choice) => {
    const correct_answer = this.props.questions[this.state.currentQuestionId].correct_answer
    let correct = (correct_answer === choice)
    if (this.state.currentQuestionId + 1 >= this.props.questions.length) {
      this.setState({
        answers: [...this.state.answers, choice],
        currentScore: this.state.currentScore + (correct ? 1 : 0),
        currentPage: 2
      }, () => {
        this.sendResults()
      })
    }
    else {
      this.setState({
        answers: [...this.state.answers, choice],
        currentScore: this.state.currentScore + (correct ? 1 : 0),
        currentQuestionId: this.state.currentQuestionId + 1
      })
    }
  }

  getScore = () => {
    return parseInt(this.state.currentScore * 100 / this.props.questions.length ,10)
  }

  render() {

    let disableStart = this.props.questions.length === 0;
    return (
      <div className="test-container">
        {this.state.currentPage === 0 && 
        <TestStart 
          startHandler={this.start} 
          disableStart={disableStart}
          nQuestions={this.props.questions.length}
          currentQuestionsId={this.props.currentQuestionsId}
        />
        }
        {this.state.currentPage === 1 && 
          <TestApp
            questionsData={this.props.questions}
            currentQuestionId={this.state.currentQuestionId}
            handleSelectAnswer={this.selectAnswerHandler}
          />
        }
        {this.state.currentPage === 2 && 
          <TestEnd 
            handleRestart={this.restart}
            currentScore={this.state.currentScore}
            nQuestions={this.props.questions.length}
            answers={this.state.answers}
            questionsData={this.props.questions}
            score={this.getScore()}
          />
        }
      </div>
      );
  }
}

export default Test;
