import React, { Component } from 'react';
import logo_hdv from './logo_hdv_horizontal.png';
import './Header.css';

class Header extends Component {

  render() {
    return (
      <div className="header">
        <div className="logo-container">
          <img alt="" className="header-logo" src={logo_hdv} width="auto" height="80" />
        </div>
      </div>
    );
  }
}

export default Header;
