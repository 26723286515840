import React, { Component } from 'react';
// import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './ResultsTable.css';
import moment from 'moment'
import 'moment/locale/es'
import MUIDataTable from "mui-datatables";



class ResultsTable extends Component {

  render() {
    
    const columns = ["Nombre", "Rut", "Unidad", "Puntaje", "Fecha"];
    const results = this.props.results ? this.props.results : [];
    const data = results.map((r) => {
      return [
        r.name !== "" ? r.name : "Sin nombre",
        r.rut !== "" ? r.rut : "Sin rut",
        "Unidad " + r.testId,
        r.score, 
        moment(r.createdAt).format("DD/MM/YYYY HH:mm")
      ]
    });

		const options = {
			textLabels: {
				body: {
					noMatch: "No se encontraron resultados",
					toolTip: "Ordenar",
				},
				pagination: {
					next: "Página siguiente",
					previous: "Página anterior",
					rowsPerPage: "Filas por página:",
					displayRows: "de",
				},
				toolbar: {
					search: "Buscar",
					downloadCsv: "Descargar CSV",
					print: "Imprimir",
					viewColumns: "Ver Columnas",
					filterTable: "Filtrar",
				},
				filter: {
					all: "Todo",
					title: "FILRTOS",
					reset: "REESTABLECER",
				},
				viewColumns: {
					title: "Ver Columnas",
					titleAria: "Ver/Esconder Columnas",
				},
				selectedRows: {
					text: "fila(s) seleccionada(s)",
					delete: "Eliminar",
					deleteAria: "Eliminar filas seleccionadas",
				},
			},
			selectableRows: false,
      rowsPerPagea: 20,
      rowsPerPageOptions: [20, 50, 200]
    };


    return (
      <MUIDataTable
        title={"Resultados de cuestionarios curso grúa horquilla"}
        data={data}
        columns={columns}
        options={options}
      />
      );
  }
}

export default ResultsTable;
