import React, { Component } from 'react';
import './Test.css';
// import warehouse from './warehouse.png';

class TestApp extends Component {

  state = {
    currentQuestion: 0,
    disableButtons: false,
    choice: null
  }


  selectAnswer = (choice) => {
    this.setState({
      disableButtons:true,
      choice: this.props.currentQuestionId + choice
    })
    setTimeout(() => {
      this.setState({disableButtons:false}, () => {
        this.props.handleSelectAnswer(choice);
      })
    },800);
  }

  render() {

    const questions = this.props.questionsData;
    const currentQuestionId = this.props.currentQuestionId;
    const currentQuestion = questions[currentQuestionId]

    return (
      <div>
        <div className="light-bg"></div>
        <div className="left light">
          <div className="middle-triangle">
          </div>
          <div className="title">
            <span className="title-text">
              Pregunta <span className="current-question-progress">
                {(currentQuestionId + 1) + "/" + questions.length}
              </span>
            </span>
          </div>
          <div className="question-title">
            {currentQuestion.question}
          </div>
          <div className="question-progress">
            {questions.map((q, i) => {
              let _class = i < currentQuestionId ? "completed" : "";
              _class = currentQuestionId === i ? "current" : _class;
              return <div 
                  key={q.id} 
                  className={"progress-dot " + _class}
                />
            })}
          </div>
        </div>
        <div className="right dark">
          <div className="title">
            <span className="title-text">
              Seleccione la alternativa correcta
            </span>
          </div>
          <div className="answers">
            {Object.entries(currentQuestion.answers).map((a,i) => {
              let choice = a[0];
              let choice_key = currentQuestionId + choice;
              return <div className={"answer-option " + (this.state.disableButtons ? " option disabled " : "") + (this.state.choice === choice_key ? " selected" : "")}
                key={currentQuestionId + choice}
                onClick={() => {this.selectAnswer(choice)}}
              >
                <div className={"answer-num "+choice}>{choice.toUpperCase()}</div>
                <span className={"answer-text "+choice}>
                  {currentQuestion.answers[choice]}
                </span>
              </div>
              })}
          </div>
        </div>
      </div>
      );
  }
}
              // let answer = a[1];

export default TestApp;
