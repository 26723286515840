import React, { Component } from 'react';
import Test from './component.js';

class TestContainer extends Component {

  state = {
    questions: []
  }

  componentDidMount() {
    this.getQuestions()
    .then(res => {
      if (res !== undefined) {
        this.setState({ questions: res.questions })
      }
    }).catch(err => console.log(err));
  }

  getQuestions = async () => {
    let question_id = this.props.match.params.id
    let url = this.props.serverUrl + '/questions/' + question_id;
    const response = await fetch(url);
    let body = await response.json()

    if (response.status !== 200) {
      throw Error(body.message)
    }
    return body;
  }

  sendResults = (results) => {
    let question_id = this.props.match.params.id
    const payload = {
      name:results.name,
      rut:results.rut,
      score:results.score,
      testId: question_id
    }; // <---- semicolon required

    (async () => {

      let url = this.props.serverUrl + '/results/create';
      console.log(url)
      await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
    })();
  }


  render() {

    let questions = this.state.questions

    return (
      <div>
        <Test 
          currentQuestionsId={this.props.match.params.id}
          sendResultsHandler={this.sendResults}
          questions={questions}
        />
      </div>
    );
  }
}

export default TestContainer;
