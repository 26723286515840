import React from 'react';
import arrowImg from './left-arrow-reverse.png';

const CaptivateButtons = () => {
  return <div>
    <img className="arrow-captivate arrow-back" alt="" src={arrowImg} width="50" height="50"/>
    <img className="arrow-captivate arrow-next" alt="" src={arrowImg} width="50" height="50"/>
  </div>
};
export default CaptivateButtons;
