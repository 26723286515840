import React, { Component } from 'react';
// import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
import CaptivateButtons from './captivateButtons.js';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

class TestEnd extends Component {

  restart = () => {
    this.props.handleRestart();
  }

  exit = () => {
    // try  {
      // document.getElementsByClassName('breadcrumb-button')[0].children[0].click();
    // } catch(error) { console.log(error); }
    try  {
      window.parent.document.getElementsByClassName('tabbar')[0].style.display = 'none';
    } catch(error) { console.log(error); }
    try  {
      window.parent.document.getElementsByClassName('back-button')[0].click();
    } catch(error) { console.log(error); }
  }



  render() {

    const rawscore = this.props.currentScore;

    const questions = this.props.questionsData;

    return (
      <div className="end">
        <div className="left light">
          <div className="middle-triangle">
          </div>
          <div className="end-title">
            <span className="title-text-end">
              Ha finalizado el cuestionario
            </span>
            <div className="end-info">
            <div>
              <span>
              Su puntaje ha sido enviado.
              </span>
            </div>
            <div className="end-results">
              {"Usted obtuvo "}
              <span className="correct-answers">{rawscore}</span> 
              {` respuesta${rawscore > 1 ? "s" : ""} correcta${rawscore > 1 ? "s" : ""} de un total de ${this.props.nQuestions}, su puntaje es:`}
            </div>
            <div className="end-score">
              {this.props.score + "%"} 
            </div>
            </div>
          </div>
        </div>
        <div className="right dark">
          <div className="title">
            <span className="title-text">
              Cuestionario de Grúa Horquilla
            </span>
          </div>
            <div className="end-results-details">
              <Paper className="paper-question-details">
                      {questions.map((qu, i) => {
                        console.log(this.props.answers)
                        console.log(this.props.answers[i])
                        return <div key={qu.id} className="question-details">
                        <div className="question-details-text">
                          {qu.question}
                        </div>
                        { this.props.answers[i] !== qu.correct_answer &&
                        <div className="question-details-answer-container">
                          <div className="question-details-answer-num incorrect">
                            {this.props.answers[i]}
                          </div>
                          <div className="question-details-answer-text">
                            {qu.answers[this.props.answers[i]]}
                          </div>
                        </div>
                        }
                        <div className="question-details-answer-container">
                          <div className="question-details-answer-num correct">
                            {qu.correct_answer}
                          </div>
                          <div className="question-details-answer-text">
                            {qu.answers[qu.correct_answer]}
                          </div>
                        </div>
                      </div>
                      })}
              </Paper>
            </div>
        </div>
        <CaptivateButtons/>
      </div>
      );
  }
}

export default TestEnd;
// no funciona por cross-origin
            // <br/>
            // <div>
              // Para salir de la actividad haga clic en Salir
            // </div>
            // <div>
            // <Button 
              // onClick={this.exit}
              // variant="contained"
              // color="primary"
              // className="exit-btn">
              // Salir
            // </Button>
            // </div>
//
//
              // {questions.map((qu, i) => {
              // return <div key={qu.id}>
                // {qu.question}
                // {Object.entries(qu.answers).map((an, i) => {
                // return <div key={an[0]}>
                  // {an[1]}
                // </div>
                // })}
              // </div>
              // })}
                        // return <Tooltip 
                          // title={an[1]} 
                          // key={qu.id + "_" + an[0]} 
                          // placement="bottom"
                        // >
                          // <div 
                            // className={"answer-num-end " + status}>
                          // <Typography className="answer-num-end-text">{an[0]}</Typography>
                          // </div>
                        // </Tooltip>
//
//
                        // {Object.entries(qu.answers).map((an, j) => {
                        // let status = "";
                        // if (an[0] === this.props.answers[i]) {
                          // status += "selected ";
                        // }

                        // if (an[0] === qu.correct_answer) {
                          // status += "correct ";
                        // }

                        // if (qu.correct_answer !== this.props.answers[i] && this.props.answers[i] === an[0]) {
                          // status += " incorrect";
                        // }
                        // return <div 
                          // key={qu.id + "_" + an[0]} 
                          // className={"answer-num-end " + status}>
                          // <Typography className="answer-num-end-text">{an[0]}</Typography>
                        // </div>
                        // })}
            // <div>
              // Puede volver a tomar el cuestionario haciendo clic en el siguiente botón
            // </div>
            // <br/>
            // <div>
            // <Button 
              // onClick={this.restart}
              // variant="contained"
              // color="primary"
              // className="restart-btn">
              // Re-tomar cuestionario
            // </Button>
            // </div>
