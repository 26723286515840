import React, { Component } from 'react';
import './App.css';
import TestResultsContainer from './TestResults';
import { 
  // BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
} from 'react-router-dom';
import TestContainer from './Test';

class App extends Component {

  render() {
    const serverUrl = false ? "http://localhost:5000" : "https://hdvapi.campuschile.cl";
    return (
      <div className="App">
          <HashRouter>
            <Switch>
              <Route path='/cuestionario/:id' render={(props) => <TestContainer {...props} serverUrl={serverUrl}/>}/>
              <Route path='/resultados' render={(props) => <TestResultsContainer {...props} serverUrl={serverUrl}/>}/>
              <Route path='/' render={(props) => <TestResultsContainer {...props} serverUrl={serverUrl}/>}/>
            </Switch>
          </HashRouter>
      </div>
    );
  }
}

export default App;
