import React, { Component } from 'react';
import { 
  Card,
  CardContent,
  TextField,
  Button,
} from '@material-ui/core';
import bgimg from './grua_ok_2.png';

class TestStart extends Component {

  state = {
    name:'',
    rut:''
  }

  handleNameChange = (e) => {
    this.setState({
      name:e.target.value
    });
  }

  handleRutChange = (e) => {
    this.setState({
      rut:e.target.value
    });
  }

  start = () => {
    this.props.startHandler(this.state.name, this.state.rut)
  }

  render() {

    const disableStart = this.props.disableStart;
    let title = "Cuestionario unidad " + this.props.currentQuestionsId;
    if (parseInt(this.props.currentQuestionsId, 10) === 6) {
      title = "Cuestionario Licencia clase D";
    }
    let nQuestions = this.props.nQuestions;
    const subtitle = disableStart ? "Conectando con el servidor..." : "Listo para iniciar. Este cuestionario contiene "+nQuestions+" preguntas";


    return (
      <div className="start">
        <div className="start-background">
          <div>
            <img src={bgimg} alt="" className="start-bg-img" />
            <div className="start-bg-img-blocker"></div>
          </div>
          <Card className="start-card-input-container">
            <CardContent>
            <div className="start-input-container">
              <div className="start-test-title">
                {title}
              </div>
              <div className="start-test-subtitle">
                {subtitle}
              </div>
              <TextField
                className="start-login-input"
                id="standard-name"
                label="Nombre"
                value={this.state.name}
                onChange={this.handleNameChange}
                margin="normal"
              />
              <TextField
                className="start-login-input"
                id="standard-rut"
                label="Rut"
                value={this.state.rut}
                onChange={this.handleRutChange}
                margin="normal"
              />
              <Button 
                onClick={this.start}
                disabled={disableStart}
                variant="contained"
                color="primary"
                className="start-btn">Comenzar cuestionario</Button>
          </div>
          </CardContent>
        </Card>
      </div>
    </div>
    );
  }
}

export default TestStart;
