import React, { Component } from 'react';
import './TestResults.css';
// import Log from './Log';
import ResultsTable from './ResultsTable';


class TestResults extends Component {

  render() {
    
    return (
      <div className="test-results">
        <ResultsTable {...this.props} />
      </div>
      );
  }
}
        // <Log {...this.props} />

export default TestResults;
