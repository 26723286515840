import React, { Component } from 'react';
import TestResults from './component.js';
import Header from '../Header';

class TestResultsContainer extends Component {


  state = {
    intervalId: -1,
    results: []
  };

  componentDidMount() {
    this.getResults()
      .then(res => this.setState({ results: res }))
      .catch(err => console.log(err));
    var intervalId = setInterval(this._update, 2000);
    this.setState({intervalId:intervalId})
  }

  _update = () => {
    this.getResults()
      .then(res => this.setState({ results: res }))
      .catch(err => console.log(err));
  }


  getResults = async () => {
    const url = this.props.serverUrl + '/results';
    const response = await fetch(url);
    let body = await response.json()

    if (response.status !== 200) {
      throw Error(body.message)
    }
    return body;
  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  }

  render() {

    console.log(this.state.results)

    return (
      <div>
        <Header/>
        <div className="test-results">
          <TestResults results={this.state.results} />
        </div>
      </div>
      );
  }
}

export default TestResultsContainer
